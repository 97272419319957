<template>
    <div>
        <div class="row m-0 mb-2 p-2 mct-barra-sub">
            <button type="button" class="btn btn-success" aria-label="Left Align" @click="$router.push({path: '/escreceber/novo'})">
                <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Contas à Receber </button>
            <button type="button" class="btn btn-danger ml-1" aria-label="Left Align" @click="$router.push({path: '/escpagar/novo'})">
                <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Contas à Pagar </button>

            <button type="button" class="btn btn-secondary ml-1" aria-label="Left Align" @click="$router.push({path: '/esccentrocusto'})">
                <span class="fa fa-th-large fa-lg" aria-hidden="true"></span> Centro de Custo </button>
            <button type="button" class="btn btn-secondary ml-1" aria-label="Left Align" @click="$router.push({path: '/escplncontas'})">
                <span class="fa fa-th-large fa-lg" aria-hidden="true"></span> Plano de Contas </button>
            <button type="button" class="btn btn-secondary ml-1" aria-label="Left Align" @click="$router.push({path: '/esccondpag'})">
                <span class="fa fa-th-large fa-lg" aria-hidden="true"></span> Cond. Pagto </button>
            <button type="button" class="btn btn-secondary ml-1" aria-label="Left Align" @click="loadModal('modulos/financeiro/EscLancamentos','esclancfinanceiro','Lançamentos Financeiros')">
                <span class="fa fa-th-large fa-lg" aria-hidden="true"></span> Lançamentos Financeiros </button>
        </div>
        <modal/>
        <router-view /> 
    </div>   
</template>

<script>
  import Vue from 'vue'

  import { Modal } from '@/components/modal'

  export default {
    name: 'EscFinanceiro',
    components: {
        Modal
    },        
    data() {
      return {
                      
      }
    },
      
    methods: {
        loadModal(component, source, titulo) {
            this.$modal.open({
                id: source,
                component: import('@/components/modal/BasicModal.vue'),
                options: {
                    show: true,
                    destroy: true,
                },
                props: {
                    component: component,
                    source: `${source}/novo`, 
                    titulo: titulo, 
                    modo: 'novo'
                },
            })
        }
      }        
  }
</script>